import {useRef, useEffect, useState} from "react";

export const useDebounce = <T extends any>(value: T, delay: number): T => {
  const handler = useRef<NodeJS.Timeout>();
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    clearTimeout(handler.current);

    handler.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler.current);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
