import React from "react";
import {
  Page,
  Text,
  View,
  Document as PdfDocument,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import {PageSize} from "@react-pdf/types";

import {FormLabelType, FormPrintOptionsType} from "../Types";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    //border: '1px solid red',
  },
  name: {
    fontWeight: 'bold',
  },
  capacity: {
    fontStyle: 'italic',
  },
  discountBox: {
    flexDirection: 'row',
    borderColor: 'red',
    borderStyle: 'solid',
  },
  discount: {
    backgroundColor: 'red',
    color: 'yellow',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  priceBox: {
    justifyContent: 'center',
  },
  oldPrice: {
    textDecoration: 'line-through',
    fontSize: 12,
  },
  newPrice: {
    fontSize: 40,
    fontWeight: 'bold',
    color: 'red',
  },
  date: {
    fontSize: 12,
  },
  companyLogo: {
    alignSelf: 'flex-end',
  },
});

export const Document = ({printOptions, labels}: { printOptions: FormPrintOptionsType, labels: FormLabelType[] }) => {
  const themePage = {
    white: {
      backgroundColor: "#fff",
      color: "black",
    },
    yellow: {
      backgroundColor: "yellow",
      color: "black",
    },
  };

  const themeLabelBySize = {
    A4: {
      //border: "1px solid red",
      width: "29.7cm",
      height: "21cm",
      padding: 30,
    },
    A5: {
      //border: "1px solid red",
      width: "21cm",
      height: "14.8cm",
      padding: 25,
    },
    A6: {
      //border: "1px solid red",
      width: "14.8cm",
      height: "10.5cm",
      padding: 20,
    },
    A7: {
      //border: "1px solid red",
      width: "10.5cm",
      height: "7.4cm",
      padding: 15,
    },
  };

  const themeHeaderBySize = {
    A4: {
      height: 125,
    },
    A5: {
      height: 100,
    },
    A6: {
      height: 80,
    },
    A7: {
      height: 60,
    },
  };

  const themeIconBySize = {
    A4: {
      marginRight: 10,
    },
    A5: {
      marginRight: 10,
    },
    A6: {
      marginRight: 10,
    },
    A7: {
      marginRight: 10,
    },
  };

  const themeNameBySize = {
    A4: {
      fontSize: 40,
    },
    A5: {
      fontSize: 30,
    },
    A6: {
      fontSize: 20,
    },
    A7: {
      fontSize: 10,
    },
  };

  const themeCapacityBySize = {
    A4: {
      fontSize: 20,
    },
    A5: {
      fontSize: 15,
    },
    A6: {
      fontSize: 10,
    },
    A7: {
      fontSize: 5,
    },
  };

  const themeCompanyLogoBySize = {
    A4: {
      height: 60,
      marginTop: 30,
    },
    A5: {
      height: 50,
    },
    A6: {
      height: 40,
    },
    A7: {
      height: 30,
    },
  };

  const themeDiscountBoxBySize = {
    A4: {
      height: 200,
      marginTop: 60,
      borderWidth: 5,
    },
    A5: {
      height: 200,
      marginTop: 60,
      borderWidth: 5,
    },
    A6: {
      height: 200,
      marginTop: 60,
      borderWidth: 5,
    },
    A7: {
      height: 200,
      marginTop: 60,
      borderWidth: 5,
    },
  };

  const themeDiscountBySize = {
    A4: {
      padding: 20,
      fontSize: 40,
      width: 200,
    },
    A5: {
      padding: 20,
      fontSize: 40,
      width: 200,
    },
    A6: {
      padding: 20,
      fontSize: 40,
      width: 200,
    },
    A7: {
      padding: 20,
      fontSize: 40,
      width: 200,
    },
  };

  const getPageOrientation = (size: PageSize) => {
    switch (size) {
      case "A4":
        return "landscape";
      case "A5":
        return "portrait";
      case "A6":
        return "landscape";
      case "A7":
        return "portrait";
      default:
        return "landscape";
    }
  }

  // @ts-ignore
  return (
    <PdfDocument>
      <Page
        orientation={getPageOrientation(printOptions.size)}
        size={"A4"}
        style={{...styles.page, ...themePage[printOptions.paperColor]}}
      >
        {labels.map((label) => (
          // @ts-ignore
          <View style={themeLabelBySize[printOptions.size]}>
            {/* @ts-ignore */}
            <View style={{...styles.header, ...themeHeaderBySize[printOptions.size]}}>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                {/* @ts-ignore */}
                {label.icon && <Image src={label.icon} style={themeIconBySize[printOptions.size]} />}
                <View>
                  {/* @ts-ignore */}
                  <Text style={{...styles.name, ...themeNameBySize[printOptions.size]}}>{label.name}</Text>
                  {/* @ts-ignore */}
                  <Text style={{...styles.capacity, ...themeCapacityBySize[printOptions.size]}}>{label.capacity}</Text>
                </View>
              </View>
            </View>

            {/* @ts-ignore */}
            <Image src={"logo-supra-couleur.png"} style={{...styles.companyLogo, ...themeCompanyLogoBySize[printOptions.size]}} />

            {/* Section de réduction */}
            {/* @ts-ignore */}
            <View style={{...styles.discountBox, ...themeDiscountBoxBySize[printOptions.size]}}>
              {/* @ts-ignore */}
              <Text style={{...styles.discount, ...themeDiscountBySize[printOptions.size]}}>{label.discount}</Text>
              <View style={styles.priceBox}>
                {/* @ts-ignore */}
                <Text style={styles.oldPrice}>{label.oldPrice && label.oldPrice + '€'}</Text>
                {/* @ts-ignore */}
                <Text style={styles.newPrice}>{label.price && label.price + '€'}</Text>
                {/* @ts-ignore */}
                <Text style={styles.date}>{label.discountDetail}</Text>
              </View>
            </View>
          </View>
        ))}
      </Page>
    </PdfDocument>
  );
};

export default Document;
