import React, {useCallback, useEffect, useState} from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
  Box,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import {Euro as EuroIcon, LocalOffer as LocalOfferIcon} from "@mui/icons-material";
import slugify from "slugify";

import {FormLabelType} from "../Types";
import {useDebounce} from "../Hooks";

export const LabelForm = ({ data, isFirst, addNewLabel, onChangeLabel, onDeleteLabel } : {data: FormLabelType, isFirst: boolean, addNewLabel: any, onChangeLabel: any, onDeleteLabel: any}) => {
  const [label, setLabel] = useState<FormLabelType>(data);
  const debouncedValues = useDebounce(label, 500);

  // Form handles
  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLabel({
        ...label,
        [event.target.name]: event.target.value,
      });
    },
    [label]
  );

  const handleSelectChange = useCallback(
    (event: SelectChangeEvent) => {
      setLabel({
        ...label,
        [event.target.name]: event.target.value,
      });
    },
    [label]
  );

  useEffect(() => {
    onChangeLabel(debouncedValues);
  }, [debouncedValues]);

  // Icons import
  function importAll(r: any) {
    return r.keys().map(r);
  }

  const images = importAll(require.context('../Icons', false, /\.(png|jpe?g|svg)$/));

  return (
    <>
      <Box component="form" sx={{mt: 1}}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormControl fullWidth sx={{mt: 2}}>
              <InputLabel id="icon-label" size="small">
                Icon
              </InputLabel>
              <Select
                size="small"
                labelId="icon-label"
                id="icon"
                name="icon"
                value={label.icon}
                label="Icon"
                onChange={handleSelectChange}
              >
                {images.map(function (image: any, index: number) {
                    return (
                      <MenuItem value={image} key={index}>
                        <img src={image} width="20"/>
                        {slugify(image.split('/').pop().split('.').shift(), {lower: true})}
                      </MenuItem>
                    )
                  }
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={9}>
            <TextField
              fullWidth
              required
              size="small"
              margin="normal"
              id="name"
              label="Nom du produit"
              name="name"
              value={label.name}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              size="small"
              margin="normal"
              id="capacity"
              label="Contenance du produit"
              name="capacity"
              value={label.capacity}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              margin="normal"
              id="discount"
              label="Promotion"
              name="discount"
              multiline={true}
              value={label.discount}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalOfferIcon fontSize="small"/>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              margin="normal"
              id="discountDetail"
              label="Détail promotion"
              name="discountDetail"
              value={label.discountDetail}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item sm={6}>
            <TextField
              fullWidth
              required
              size="small"
              margin="normal"
              id="price"
              label="Prix"
              name="price"
              type="number"
              value={label.price}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EuroIcon fontSize="small"/>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item sm={6}>
            <TextField
              fullWidth
              size="small"
              margin="normal"
              id="oldPrice"
              label="Prix barré"
              name="oldPrice"
              type="number"
              value={label.oldPrice}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EuroIcon fontSize="small"/>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6}>
            {isFirst ? null : (
              <Button
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
                onClick={onDeleteLabel}
                color={"error"}
              >
                Supprimer l'étiquette
              </Button>
            )}
          </Grid>

          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2}}
              onClick={addNewLabel}
              color={"success"}
            >
              Ajouter une étiquette
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default LabelForm;
