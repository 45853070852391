import { PageSize } from "@react-pdf/types";

export type FormPrintOptionsType = {
  size: PageSize;
  manageColor: boolean;
  paperColor: FormPaperColorEnum;
};

export type FormLabelType = {
  id: number;
  name: string;
  capacity: string;
  discount: string;
  discountDetail: string;
  price: string;
  oldPrice: string;
  icon: string;
};

export enum FormPaperColorEnum {
  WHITE = "white",
  YELLOW = "yellow",
}
